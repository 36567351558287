/** @jsxImportSource theme-ui */
import React from "react";
import { Annotation, Connector, HtmlLabel } from "@visx/annotation";
import { usePieAnnotationPosition } from "../../../hooks/use-pie-annotation-position";

const PieDataAnnotation = ({ fontSize, title, arcColor, arc, path }) => {
  const { surfaceX, surfaceY, labelX, labelY } = usePieAnnotationPosition({
    arc,
    path,
  });

  return (
    <Annotation
      x={surfaceX}
      y={surfaceY}
      dx={labelX - surfaceX}
      dy={labelY - surfaceY}
    >
      <Connector type="elbow" stroke={arcColor} />
      <HtmlLabel
        showBackground={false}
        showAnchorLine={false}
        containerStyle={{
          width: "4ch",
          height: "2rem",
          color: arcColor,
          fontSize,
        }}
      >
        {title}
      </HtmlLabel>
    </Annotation>
  );
};

export default PieDataAnnotation;

import { graphql, useStaticQuery } from "gatsby";

export default function useYearlyCategoricalAlarms() {
  const {
    allMarkdownRemark: { group },
  } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateName: { eq: "news/alarms" } } }
      ) {
        group(field: fields___calledAtYear) {
          year: fieldValue
          nodes {
            frontmatter {
              type
            }
          }
        }
      }
    }
  `);

  const data = group
    .map((y) => {
      // foreach year
      const { nodes, ...obj } = y; // copy nodes into unused variable, use obj where everything else is
      const occurences_map = nodes
        .map((x) => x.frontmatter.type)
        .reduce(
          // returns map of occurences
          (acc, e) => acc.set(e, 1 + (acc.get(e) || 0)),
          new Map()
        );
      return { ...obj, ...Object.fromEntries(occurences_map) };
    })
    .sort((a, b) => parseInt(a.year) - parseInt(b.year));

  const unique_types = new Set(
    data
      .map((x) => Object.keys(x))
      .flat()
      .filter((x) => x !== "year")
  );
  const types = Array.from(unique_types).sort((a, b) => a.localeCompare(b));

  return { data, keys: types };
}

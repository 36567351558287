/** @jsxImportSource theme-ui */
import React from "react";
import { GridColumns } from "@visx/grid";
import { BarStackHorizontal } from "@visx/shape";
import { Group } from "@visx/group";
import { AxisLeft, Orientation } from "@visx/axis";
import { AnimatedAxis } from "@visx/react-spring";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { useThemeUI, css } from "theme-ui";
import Legend from "./Legend";

const getYear = (d) => d.year;
const getTotalPerYear = (d) => {
  const { year, ...rest } = d;
  return Object.values(rest).reduce((a, b) => a + b, 0);
};
const pluckKeys = (d, keys) => {
  const retVal = {};
  for (const [key, value] of Object.entries(d)) {
    if (!keys.includes(key)) {
      retVal[key] = value;
    }
  }
  return retVal;
};

const AlarmsStackedBarChart = ({
  data,
  keys,
  width,
  height,
  margin = { top: 36, left: 74, right: 36, bottom: 40 },
  legendGlyphSize,
  ...props
}) => {
  const { theme } = useThemeUI();

  const tooltipTimeoutRef = React.useRef(null);
  React.useEffect(() => {
    return () => clearTimeout(tooltipTimeoutRef.current);
  }, []);

  const [filterList, setFilterList] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  React.useEffect(() => {
    setFilteredData(data.map((d) => pluckKeys(d, filterList)));
  }, [filterList, data]);

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  });

  // Bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // Scales
  const countScale = scaleLinear({
    domain: [0, Math.max(...filteredData.map(getTotalPerYear))],
    nice: true,
  }).rangeRound([0, xMax]);
  const yearScale = scaleBand({
    domain: data.map(getYear),
    padding: 0.1,
    paddingInner: 0.3,
    bandwidth: 2,
  }).rangeRound([yMax, 0]);
  const colorScale = scaleOrdinal({
    domain: keys,
    range: keys.map((x) => theme.colors[`alarmType${x[0].toUpperCase()}`]),
  });

  const countTicks = countScale.ticks().filter(Number.isInteger);

  const fontSizes = [theme.fontSizes[0], theme.fontSizes[2]];

  const labelStyles = {
    fill: theme.colors.text,
    fontSize: fontSizes[1],
    textAnchor: "middle",
  };

  const handleMouseOverBar = (event, bar) => {
    if (tooltipTimeoutRef?.current) {
      clearTimeout(tooltipTimeoutRef.current);
    }
    const coords = localPoint(event.target.ownerSVGElement, event);
    showTooltip({
      tooltipTop: coords.y,
      tooltipLeft: coords.x,
      tooltipData: bar,
    });
  };

  const handleMouseOutBar = () => {
    tooltipTimeoutRef.current = window.setTimeout(() => {
      hideTooltip();
    }, 200);
  };

  return width < 100 ? null : (
    <div
      sx={{
        width,
        height,
        position: "relative",
        fontFamily: theme.fonts.body,
      }}
      {...props}
    >
      <Legend
        colorScale={colorScale}
        legendGlyphSize={legendGlyphSize}
        toggledItems={filterList}
        toggleHandler={(label) => {
          const { text } = label;

          // Toggle filter
          setFilterList(
            filterList.includes(text)
              ? filterList.filter((x) => x !== text) // remove filter
              : [...filterList, text] // add filter
          );
        }}
      />
      <svg width={width} height={height} ref={containerRef}>
        <rect
          width={width}
          height={height}
          fill={theme.colors.background}
          rx={14}
        />
        <GridColumns
          top={margin.top}
          left={margin.left}
          scale={countScale}
          tickValues={countTicks}
          height={yMax}
          style={{
            stroke: theme.colors.muted,
            strokeDasharray: "2 3",
          }}
        />
        <Group top={margin.top} left={margin.left}>
          <BarStackHorizontal
            data={filteredData}
            keys={keys}
            height={yMax}
            y={getYear}
            xScale={countScale}
            yScale={yearScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) =>
                  bar.width < 0 ? null : (
                    <rect
                      key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                      x={bar.x}
                      y={bar.y}
                      width={bar.width}
                      height={bar.height}
                      fill={bar.color}
                      onTouchMove={(e) => handleMouseOverBar(e, bar)}
                      onTouchCancel={handleMouseOutBar}
                      onMouseMove={(e) => handleMouseOverBar(e, bar)}
                      onMouseLeave={handleMouseOutBar}
                    />
                  )
                )
              )
            }
          </BarStackHorizontal>
          <AxisLeft
            scale={yearScale}
            label="Jahr"
            labelProps={{ ...labelStyles, dx: "-0.4rem" }}
            stroke={theme.colors.muted}
            tickStroke={theme.colors.muted}
            tickLabelProps={() => ({
              fill: theme.colors.text,
              fontSize: fontSizes[0],
              textAnchor: "end",
              dy: "0.25rem",
              dx: "-0.2rem",
            })}
          />
          <AnimatedAxis
            orientation={Orientation.bottom}
            animationTrajectory="min"
            top={yMax}
            label="Anzahl Einsätze"
            labelProps={{ ...labelStyles, dy: "0.4rem" }}
            scale={countScale}
            numTicks={data.length}
            tickValues={countTicks}
            tickFormat={(x) => Math.round(x)}
            stroke={theme.colors.muted}
            tickStroke={theme.colors.muted}
            tickLabelProps={() => ({
              fill: theme.colors.text,
              fontSize: fontSizes[0],
              textAnchor: "middle",
              dy: "0.6rem",
            })}
          />
        </Group>
      </svg>
      {tooltipOpen && (
        <TooltipInPortal
          key={Math.random()} // needed for bounds to update correctly
          top={tooltipTop}
          left={tooltipLeft}
          style={css({
            position: "absolute",
            bg: "background",
            px: 2,
            py: 1,
            borderRadius: "medium",
            border: (t) => `1px solid ${t.colors.shade5}`,
            zIndex: "tooltips",
          })(theme)}
        >
          <span sx={{ color: colorScale(tooltipData.key) }}>
            <svg
              sx={{
                width: "12px",
                height: "12px",
              }}
            >
              <g transform="translate(6, 6)">
                <circle r="6.0" fill={colorScale(tooltipData.key)} />
              </g>
            </svg>
            {` ${tooltipData.key} ${data.map(getYear)[tooltipData.index]}: `}
          </span>
          {tooltipData.bar.data[tooltipData.key]}
        </TooltipInPortal>
      )}
    </div>
  );
};

export default AlarmsStackedBarChart;

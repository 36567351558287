/** @jsxImportSource theme-ui */
import React from "react";
import { LegendOrdinal, LegendItem, LegendLabel } from "@visx/legend";

const Legend = ({
  colorScale,
  legendGlyphSize = 14,
  toggledItems = [],
  toggleHandler,
  ...props
}) => (
  <div
    sx={{
      position: "absolute",
      top: 2,
      width: "100%",
      display: "flex",
      justifyContent: "center",
    }}
    {...props}
  >
    <LegendOrdinal scale={colorScale}>
      {(labels) => (
        <div sx={{ display: "flex", flexDirection: "row" }}>
          {labels.map((label, i) => (
            <LegendItem
              key={`legend-ordinal-${i}`}
              onClick={() => {
                if (toggleHandler) {
                  toggleHandler(label);
                }
              }}
              style={{}}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mx: 2,
                "&:hover": !toggleHandler
                  ? {}
                  : {
                      cursor: "pointer",
                      color: label.value,
                    },
                ...(toggledItems.includes(label.text) && {
                  color: "muted",
                  textDecoration: "line-through",
                }),
              }}
            >
              <svg width={legendGlyphSize} height={legendGlyphSize}>
                <g
                  transform={`translate(${legendGlyphSize / 2}, ${
                    legendGlyphSize / 2
                  })`}
                >
                  <circle fill={label.value} r={legendGlyphSize / 2} />
                </g>
              </svg>
              <LegendLabel margin="0 0 0 4px">{label.text}</LegendLabel>
            </LegendItem>
          ))}
        </div>
      )}
    </LegendOrdinal>
  </div>
);

export default Legend;

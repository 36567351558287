/** @jsxImportSource theme-ui */
import React from "react";
import { graphql } from "gatsby";
import Layout from "../../../components/Layout";
import CurrentPage from "../../../components/CurrentPage";
import Pagination from "../../../components/Pagination";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Link from "../../../components/Link";
import Seo from "../../../components/Seo";
import AlarmsPieChart from "./AlarmsPieChart";
import AlarmsStackedBarChart from "./AlarmsStackedBarChart";
import { BaseStyles, Card } from "theme-ui";
import { formatLocalDateTime } from "../../../utils/dateTime";
import { ParentSize } from "@visx/responsive";
import { keyframes } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useYearlyCategoricalAlarms from "../../../hooks/use-yearly-categorical-alarms";
import useCategoricalAlarms from "../../../hooks/use-categorical-alarms";

const cardEntryAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate(-100%, 0) scale(0.3);
  }
  to {
    opacity: 1;
    transform: translate(0, 0) scale(1.0);
  }
`;

const nthChildAnimations = Array.from({ length: 20 }, (_, i) => ({
  [`:nth-of-type(${i + 1})`]: {
    animationDelay: `${50 * i}ms`,
  },
})).reduce((a, b) => Object.assign(a, b), {});

const AlarmCard = ({
  alarm: {
    fields: { slug },
    frontmatter: { title, type, location, calledAt },
  },
  ...props
}) => (
  <Link
    to={slug}
    variant="card"
    {...props}
    sx={{
      animation: `${cardEntryAnimation} .5s ease-out`,
      animationFillMode: "backwards",
      ...nthChildAnimations,
    }}
  >
    <Card tag="article" variant="labelled">
      <div
        sx={{
          position: "absolute",
          width: "2.2rem",
          height: "2.2rem",
          fontSize: "1.1rem",
          fontWeight: "900",
          color: "lightText",
          borderTopLeftRadius: "medium",
          borderBottomRightRadius: "medium",
          backgroundColor: (t) => t.colors[`alarmType${type[0].toUpperCase()}`],
          "::before": {
            width: "100%",
            textAlign: "center",
            display: "inline-block",
            verticalAlign: "middle",
            lineHeight: "2.2rem",
            content: `'${type[0].toUpperCase()}'`,
          },
        }}
      />
      <div
        sx={{
          px: 3,
          py: 2,
          display: "flex",
          flexDirection: "column",
          flex: "1 1",
          justifyContent: "center",
        }}
      >
        <header>
          <h3>{title}</h3>
        </header>

        <main sx={{ fontSize: 1 }}>
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              "> *:last-child": {
                textAlign: "right",
              },
            }}
          >
            <span>Alarmierung um: </span>
            <span>{formatLocalDateTime(calledAt)}</span>
          </div>

          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              "> *:last-child": {
                textAlign: "right",
              },
            }}
          >
            <span>Einsatzort: </span>
            <span>{location}</span>
          </div>
        </main>
      </div>
    </Card>
  </Link>
);

const PaginationTemplate = ({ data, pageContext, location }) => {
  const { edges } = data.allMarkdownRemark;
  const { data: barChartData, keys: barChartKeys } =
    useYearlyCategoricalAlarms();
  const { group: pieChartData } = useCategoricalAlarms();

  return (
    <>
      <Seo
        title="Einsätze"
        pathname={location.pathname}
        description="Letzte Einsätze und Einsatzberichte der Feuerwehr Arborn."
      />

      <Layout>
        <Breadcrumbs names={["Einsätze"]} location={location} />
        <BaseStyles>
          <h2>Einsätze</h2>
          <p>
            <FontAwesomeIcon icon="bullhorn" /> Januar 2023: Alle Einsätze
            werden ab sofort auf{" "}
            <Link
              href="https://www.feuerwehr-greifenstein.org/"
              target="_blank"
            >
              www.feuerwehr-greifenstein.org
            </Link>{" "}
            veröffentlicht.
          </p>
          <CurrentPage pageContext={pageContext} />
          <div
            sx={{
              display: "flex",
              gap: 5,
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <section
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: "1 1 560px",
              }}
            >
              <div
                sx={{
                  display: "grid",
                  gridAutoRows: "minmax(220px, 1fr)",
                  gridTemplateColumns:
                    "repeat(auto-fill, minmax(min(240px, 100%), 1fr))",
                  gridGap: 4,
                }}
              >
                {edges.map((edge) => (
                  <AlarmCard alarm={edge.node} key={edge.node.id} />
                ))}
              </div>
            </section>
            <div
              sx={{
                display: "flex",
                flex: "0 1 560px",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <aside
                sx={{
                  display: "grid",
                  gridTemplateRows: "repeat(2, min(100vmin, 420px))",
                  textAlign: "center",
                  gridGap: 4,
                  mb: 2,
                }}
              >
                <div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 0,
                  }}
                >
                  <h4>Anzahl an Einsätzen nach Kategorien seit 2012</h4>
                  <ParentSize>
                    {(parent) => (
                      <AlarmsPieChart
                        width={parent.width}
                        height={parent.height}
                        data={pieChartData}
                      />
                    )}
                  </ParentSize>
                </div>

                <div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 0,
                  }}
                >
                  <h4>
                    Anzahl an Einsätzen nach Jahren und Kategorien seit 2012
                  </h4>
                  <ParentSize>
                    {(parent) => (
                      <AlarmsStackedBarChart
                        width={parent.width}
                        height={parent.height}
                        data={barChartData}
                        keys={barChartKeys}
                      />
                    )}
                  </ParentSize>
                </div>
              </aside>
            </div>
          </div>

          <Pagination path="/einsaetze" {...pageContext} />
        </BaseStyles>
      </Layout>
    </>
  );
};

export default PaginationTemplate;

export const alarmsPaginationQuery = graphql`
  query AlarmsPagination($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { templateName: { eq: "news/alarms" } } }
      sort: { fields: frontmatter___calledAt, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            location
            calledAt
            type
          }
        }
      }
    }
  }
`;

import { graphql, useStaticQuery } from "gatsby";

export default function useCategoricalAlarms() {
  const {
    allMarkdownRemark: { group },
  } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateName: { eq: "news/alarms" } } }
      ) {
        group(field: frontmatter___type) {
          type: fieldValue
          totalCount
        }
      }
    }
  `);
  return { group };
}

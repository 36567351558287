const usePieAnnotationPosition = ({ arc, path }) => {
  const middleAngle =
    Math.PI / 2 - (arc.startAngle + (arc.endAngle - arc.startAngle) / 2);

  const outerRadius = path.outerRadius()(arc);

  const [surfaceX, surfaceY] = [
    outerRadius * Math.cos(middleAngle),
    outerRadius * Math.sin(-middleAngle),
  ];

  const distanceRatioX = 1.4;
  const distanceRatioY = 1.15;
  const offsetX = 0.225;
  const offsetY = 0.1;
  const [labelX, labelY] = [
    Math.cos(middleAngle + offsetX) * outerRadius * distanceRatioX,
    Math.sin(-middleAngle - offsetY) * outerRadius * distanceRatioY,
  ];

  return {
    labelX,
    labelY,
    surfaceX,
    surfaceY,
  };
};

export { usePieAnnotationPosition };

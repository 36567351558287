/** @jsxImportSource theme-ui */
import React from "react";
import Pie from "@visx/shape/lib/shapes/Pie";
import { scaleOrdinal } from "@visx/scale";
import { Group } from "@visx/group";
import { Text } from "@visx/text";
import { useTransition, animated, to } from "react-spring";
import { useThemeUI } from "theme-ui";
import Legend from "./Legend";
import PieDataAnnotation from "./PieDataAnnotation";

const AlarmsPieChart = ({
  data,
  animate = true,
  width,
  height,
  margin = { top: 80, right: 40, bottom: 40, left: 40 },
  ...props
}) => {
  const { theme } = useThemeUI();

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 28;

  const colorScale = scaleOrdinal({
    domain: data.map((x) => x.type),
    range: data
      .map((x) => x.type)
      .map((x) => theme.colors[`alarmType${x[0].toUpperCase()}`]),
  });

  return width < 100 ? null : (
    <div
      sx={{
        width,
        height,
        position: "relative",
        fontFamily: theme.fonts.body,
      }}
      {...props}
    >
      <Legend colorScale={colorScale} />
      <svg width={width} height={height}>
        <rect
          width={width}
          height={height}
          fill={theme.colors.background}
          rx={14}
        />
        <Group top={centerY + margin.top} left={centerX + margin.left}>
          <Pie
            data={data}
            pieValue={(d) => d.totalCount}
            outerRadius={radius}
            innerRadius={radius - donutThickness}
            cornerRadius={3}
            padAngle={0.005}
          >
            {(pie) => (
              <AnimatedPie
                {...pie}
                theme={theme}
                animate={animate}
                getKey={(arc) => arc.data.type}
                getColor={(arc) => colorScale(arc.data.type)}
              />
            )}
          </Pie>
          <Text
            verticalAnchor="middle"
            textAnchor="middle"
            fill={theme.colors.text}
            fontSize={theme.fontSizes[3]}
          >
            {`${data
              .map((x) => x.totalCount)
              .reduce((a, b) => a + b, 0)} Einsätze`}
          </Text>
        </Group>
      </svg>
    </div>
  );
};

const fromLeaveTransition = ({ endAngle }) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});

const enterUpdateTransition = ({ startAngle, endAngle }) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

function AnimatedPie({ theme, animate, arcs, path, getKey, getColor }) {
  const transitions = useTransition(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });

  return transitions((props, arc, { key }) => (
    <g key={key}>
      <animated.path
        // compute interpolated path d attribute from intermediate angle values
        d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
          path({
            ...arc,
            startAngle,
            endAngle,
          })
        )}
        fill={getColor(arc)}
        id={getKey(arc)}
      />

      {arc.endAngle - arc.startAngle !== 0 ? (
        <PieDataAnnotation
          arcColor={getColor(arc)}
          arc={arc}
          path={path}
          title={arc.value}
          fontSize={theme.fontSizes[2]}
        />
      ) : null}
    </g>
  ));
}

export default AlarmsPieChart;
